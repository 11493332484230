export const AGREGAR_UNO = "store_agregar_uno";
export const AGREGAR = "store_agregar";
export const BORRAR = "store_borrar";
export const BORRAR_UNO = "store_borrar_uno";
export const BORRAR_TODO = "store_borrar_todo";
export const CAMBIAR_CANTIDAD = "store_cambiar_cantidad";
export const ENVIAR_CONTACTO = "store_enviar_contacto";
export const ENVIAR_INFO = "store_enviar_info";
export const ENVIAR_PETICION = "store_enviar_info";
export const ENVIAR_COMPRA = "store_enviar_compra";
