import React, { Component } from "react";
import logo from "../images/logoMails.png";

class LogoMails extends Component {
  render () {
    return (
    <div>
      <img src={logo} alt=""></img>
    </div>
    )
  }
}

export default LogoMails;